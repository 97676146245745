var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "aboutDocument" }, [
    _c("div", { staticClass: "aboutDocument__text" }, [
      _c("b", [_vm._v(_vm._s(_vm.document.title))]),
      _c("span", [_vm._v("-")]),
      _c("span", [_vm._v(_vm._s(_vm.document.subtitle))]),
    ]),
    _c(
      "a",
      {
        staticClass: "aboutDocument__button",
        attrs: { href: _vm.document.link, target: "_blank" },
      },
      [
        _c("iq-button", { attrs: { size: "mini", color: "primary" } }, [
          _vm._v(" Открыть "),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }