<template>
  <div class="aboutDocument">
    <div class="aboutDocument__text">
      <b>{{ document.title }}</b>
      <span>-</span>
      <span>{{ document.subtitle }}</span>
    </div>
    <a :href="document.link" target="_blank" class="aboutDocument__button">
      <iq-button size="mini" color="primary"> Открыть </iq-button>
    </a>
  </div>
</template>

<script>
import IqButton from '@/UI/buttons/IqButton.vue'

export default {
  name: 'AboutDocument',
  components: { IqButton },
  props: {
    document: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style lang="sass" scoped>
.aboutDocument
  display: flex
  justify-content: space-between
  align-items: center
  column-gap: 60px
  margin-left: 20px
  margin-bottom: 20px
  font-size: 18px
  &__text
    display: flex
    flex-wrap: wrap
    gap: 8px

  &__button
    color: $color-blue-primary
    text-decoration: none
</style>
